import React from 'react';
import logo from './logo.png'
import './App.css';

function App() {
  return <div className='genuine__container'>
    <div className='metaltech-logo__container'>
      <img src={logo} className="metaltech-logo" alt="logo" />
    </div>
    <div className='check__container'>
      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </div>
    <div className='genuine-title__container'>
      <span>GENUINE PRODUCT</span>
    </div>
  </div>
}

export default App;
